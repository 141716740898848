import React, { useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import Player from '@vimeo/player';

import { BrowserContext } from '../../contexts/browser';
import useStateRef from '../../hooks/useStateRef';
import usePropRef from '../../hooks/usePropRef';

const tag = 'player';

/**
 * remove event listeners
 * @param {object} player
 */
function removeEventListeners(player) {
  if (!player) return;
  player.off('ended');
  player.off('pause');
  player.off('play');
}
/**
 * remove interval
 * @param {number} interval
 */
function removeInterval(interval) {
  console.tag(tag).debug('removeInterval called');
  window.clearInterval(interval);
}

/**
 * 640×480, 800×600, 960×720, 1024×768, 1280×960,
 * 1400×1050, 1440×1080 , 1600×1200, 1856×1392, 1920×1440, and 2048×1536
 * @param {number} width
 */
function computeRatio(delayedWidth, percentage = 0.9) {
  const height = window.innerHeight;
  const width = delayedWidth - delayedWidth * (1 - percentage);

  if (height <= 480) {
    return width > 640 ? 640 : width;
  }
  if (height <= 600) {
    return width > 800 ? 800 : width;
  }
  if (height <= 720) {
    return width > 960 ? 960 : width;
  }
  if (height <= 768) {
    return width > 1024 ? 1024 : width;
  }
  if (height <= 960) {
    return width > 1280 ? 1280 : width;
  }
  if (height <= 1050) {
    return width > 1400 ? 1400 : width;
  }
  if (height <= 1080) {
    return width > 1440 ? 1440 : width;
  }
  if (height <= 1200) {
    return width > 1600 ? 1600 : width;
  }
  if (height <= 1392) {
    return width > 1856 ? 1856 : width;
  }
  if (height <= 1440) {
    return width > 1920 ? 1920 : width;
  }
  if (height <= 1536) {
    return width > 2048 ? 2048 : width;
  }
  return width;
}

const VideoPlayer = ({
  index,
  link,
  onProgress,
  latestProgress,
  widthPercentage,
  onVideoEnded,
}) => {
  const { delayedWidth } = useContext(BrowserContext);
  const [, setProgress, progressRef] = useStateRef(
    latestProgress < 1 ? latestProgress : 0
  );
  const playerRef = useRef(null);
  const intervalRef = useRef(null);
  const percentageRef = usePropRef(widthPercentage);
  const widthDragRef = useRef();

  useEffect(() => {
    console.tag(tag).debug('changing delayed width', delayedWidth);

    if (!delayedWidth) {
      return;
    }

    const asyncEffect = async () => {
      const player = playerRef.current;
      if (player) {
        console.tag(tag).debug('player detected, checking fullscreen');
        const isFullscreen = await player.getFullscreen();
        console.tag(tag).debug('fullscreen detected', isFullscreen);

        if (isFullscreen) {
          return;
        }

        playerRef.current = null;
        try {
          await player.pause(); // gets rid of interval
          // pause first to trigger onPause to store progress
          removeEventListeners(player);
          player.destroy();
        } catch (error) {
          console.tag(tag).error(error);
        }
      }

      const options = {
        id: link,
        width: computeRatio(delayedWidth, widthPercentage),
      };
      const newPlayer = new Player(`frame-${index}`, options);
      playerRef.current = newPlayer;

      if (progressRef.current) {
        newPlayer.getDuration().then(duration => {
          const seconds = duration * progressRef.current;
          newPlayer.setCurrentTime(seconds);
        });
      }

      const keepTrackProgress = async () => {
        // gets duration of video in seconds
        const duration = await newPlayer.getDuration();

        intervalRef.current = window.setInterval(() => {
          const currentPlayer = playerRef.current;
          if (!currentPlayer) {
            return;
          }
          currentPlayer.getCurrentTime().then(seconds => {
            // `seconds` indicates the current playback position of the video
            const newProgress = seconds / duration;
            console
              .tag(tag)
              .debug(
                `progress: ${newProgress}, duration ${duration}, seconds ${seconds}`
              );
            if (onProgress) {
              onProgress(newProgress);
            }
            setProgress(newProgress);
          });
          // track every next 10 seconds of progress
        }, 10000);
      };

      newPlayer.on('ended', () => {
        console.tag(tag).debug('player onEnded');
        removeInterval(intervalRef.current);
        intervalRef.current = null;
        if (onProgress) {
          onProgress(1);
        }
        setProgress(1);
        if (onVideoEnded) {
          onVideoEnded();
        }
      });

      newPlayer.on('pause', ({ duration, seconds }) => {
        console.tag(tag).debug('player onPause');
        removeInterval(intervalRef.current);
        intervalRef.current = null;
        const newProgress = seconds / duration;
        console
          .tag(tag)
          .debug(
            `progress at paused: ${newProgress}, duration ${duration}, seconds ${seconds}`
          );
        if (onProgress) {
          onProgress(newProgress);
        }
        setProgress(newProgress);
      });

      newPlayer.on('play', () => {
        console.tag(tag).debug('player onPlay');
        keepTrackProgress();
      });
    };

    const widthChanged = widthDragRef.current !== delayedWidth;
    const percentageChanged = percentageRef.current !== widthPercentage;
    if (widthChanged || percentageChanged) {
      widthDragRef.current = delayedWidth;
      percentageRef.current = widthPercentage;
      asyncEffect();
    }
  }, [
    delayedWidth,
    index,
    link,
    onProgress,
    onVideoEnded,
    percentageRef,
    progressRef,
    setProgress,
    widthPercentage,
  ]);

  useEffect(
    () => () => {
      removeInterval(intervalRef.current);
      removeEventListeners(playerRef.current);
      if (playerRef.current) {
        playerRef.current.destroy();
      }
    },
    []
  );

  return <div id={`frame-${index}`} className="frame-wrapper" />;
};

VideoPlayer.propTypes = {
  index: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  onProgress: PropTypes.func,
  onVideoEnded: PropTypes.func,
  latestProgress: PropTypes.number.isRequired,
  widthPercentage: PropTypes.number,
};

VideoPlayer.defaultProps = {
  widthPercentage: 0.9,
  onVideoEnded: undefined,
  onProgress: undefined,
};

export default VideoPlayer;
