import { useRef, useEffect } from 'react';

const usePropRef = prop => {
  const ref = useRef(prop);

  useEffect(() => {
    ref.current = prop;
  }, [prop]);

  return ref;
};

export default usePropRef;
